<template>
  <div>
    <a-modal
      :title="status.isEdit ? 'EDIT REPORT SCHEDULE' : 'CREATE REPORT SCHEDULE'"
      :visible="modalReportSubmissionVisible"
      :confirm-loading="confirmLoading"
      @cancel="toggleModalAddReportSubmission"
      centered
    >
      <a-form layout="vertical">
        <a-form-item label="Tahun Ajaran">
          <a-select size="large" v-model="newReportSubmission.id_tahun_ajaran">
            <a-select-option
              v-for="tahunAjaran in tahunAjarans"
              :key="tahunAjaran.id"
            >
              {{ tahunAjaran.tahun }} ({{ tahunAjaran.semester }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Type">
          <a-radio-group
            class="w-100 text-center"
            size="large"
            v-model="newReportSubmission.tipe"
          >
            <a-radio-button class="w-50" value="mid"> MID </a-radio-button>
            <a-radio-button class="w-50" value="final"> FINAL </a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item required label="Level">
          <a-select size="large" v-model="newReportSubmission.id_level">
            <a-select-option
              v-for="level in levelList"
              :key="level.id"
              :value="level.id"
            >
              {{ level.nama }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Start Submission At">
          <a-date-picker
            v-model="newReportSubmission.tanggal_awal_submisi"
            :disabled-date="disabledStartDate"
            show-time
            size="large"
            class="w-100"
            format="YYYY-MM-DD HH:mm:ss"
            @openChange="handleStartOpenChange"
          />
        </a-form-item>
        <a-form-item label="End Submission At">
          <a-date-picker
            v-model="newReportSubmission.tanggal_akhir_submisi"
            :disabled-date="disabledEndDate"
            show-time
            size="large"
            class="w-100"
            format="YYYY-MM-DD HH:mm:ss"
            :open="endOpen"
            @openChange="handleEndOpenChange"
          />
        </a-form-item>
        <a-form-item label="Published Report At">
          <a-date-picker
            v-model="newReportSubmission.tanggal_publikasi"
            :disabled-date="disabledBeforeNow"
            show-time
            size="large"
            class="w-100"
            format="YYYY-MM-DD HH:mm:ss"
            @openChange="handleStartOpenChange"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModalAddReportSubmission"
        >
          CANCEL
        </a-button>
        <a-button
          key="submit"
          type="primary"
          size="large"
          @click="handleOkAddReportSubmission"
        >
          {{ status.isEdit ? "EDIT" : "CREATE" }} NOW
        </a-button>
      </template>
    </a-modal>
    <div class="d-md-flex align-items center mb-3">
      <div class="flex-column flex-md-row">
        <!-- <a-input
          class="mr-3"
          v-model="search"
          @keyup="searchReportSetting"
          placeholder="Search by Type"
          size="large"
          :style="{ width: '250px' }"
        ></a-input> -->
        <a-select
          :class="isMobile ? ['w-100', 'mb-4'] : null"
          size="large"
          placeholder="Select Level"
          style="min-width: 150px;"
          @change="handleSelect"
          v-model="selectedLevel"
        >
          <a-select-option
            value="all"
            >All</a-select-option
          >
          <a-select-option
            v-for="item in levelList"
            :key="item.id"
            :value="item.id"
            >{{ item.nama }}</a-select-option
          >
        </a-select>
      </div>
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          :loading="loadingAddReportSubmission"
          @click.prevent="toggleModalAddReportSubmission('add')"
          type="primary"
          size="large"
          class="ml-auto"
          icon="plus"
          >{{ loadingAddReportSubmission ? "CREATING..." : "CREATE" }}</a-button
        >
      </div>
    </div>
    <a-table
      class="hasan-table-overflow"
      :columns="columnsReportSubmission"
      :data-source="dataReportSubmission"
      :pagination="pagination"
      :loading="loadingTableReportSubmission"
      @change="handleTableChange"
      bordered
    >
      <span slot="tanggal" slot-scope="text">{{ text || "-" }}</span>
      <div
        class="d-flex justify-content-center"
        slot="Actions"
        slot-scope="text, record"
      >
        <!-- <a
          @click.prevent="finalizeReport(record)"
          class="btn btn-outline-warning m-2"
          ><a-icon class="mr-2" type="check-square" />Finalize Report</a
        > -->
        <a
          @click.prevent="toggleModalAddReportSubmission('edit', record)"
          class="btn btn-outline-success m-2"
          ><a-icon class="mr-2" type="form" />Edit</a
        >
        <a
          @click.prevent="deleteReportSchedule(record)"
          class="btn btn-outline-danger m-2"
          ><a-icon class="mr-2" type="delete" />Delete</a
        >
      </div>
    </a-table>
  </div>
</template>

<script>
import moment from 'moment'

const columnsReportSubmission = [
  {
    title: 'Tahun Ajaran',
    dataIndex: 'tahunAjaran',
    align: 'center',
    scopedSlots: { customRender: 'tahunAjaran' },
  },
  {
    title: 'Type',
    dataIndex: 'tipe',
    align: 'center',
    scopedSlots: { customRender: 'tipe' },
  },
  {
    title: 'Level',
    key: 'idLevel',
    dataIndex: 'namaLevel',
    align: 'center',
    scopedSlots: { customRender: 'namaLevel' },
  },
  {
    title: 'Submission Date',
    align: 'center',
    children: [
      {
        title: 'Start At',
        dataIndex: 'startAt',
        align: 'center',
        scopedSlots: { customRender: 'tanggal' },
      },
      {
        title: 'End At',
        dataIndex: 'endAt',
        align: 'center',
        scopedSlots: { customRender: 'tanggal' },
      },
    ],
  },
  {
    title: 'Publish Date',
    dataIndex: 'publication',
    align: 'center',
    scopedSlots: { customRender: 'tanggal' },
  },
  {
    title: 'Actions',
    dataIndex: 'Actions',
    align: 'center',
    scopedSlots: { customRender: 'Actions' },
  },
]

export default {
  data() {
    return {
      columnsReportSubmission,
      endOpen: false,
      confirmLoading: false,
      loadingTableReportSubmission: false,
      loadingAddReportSubmission: false,
      modalReportSubmissionVisible: false,
      dataReportSubmission: [],
      tahunAjarans: [],
      levelList: [],
      pagination: { current: 1 },
      search: '',
      selectedLevel: 'all',
      status: {
        isEdit: false,
        isCreate: false,
      },
      newReportSubmission: {
        id_tahun_ajaran: '',
        tipe: 'mid',
        id_level: null,
        tanggal_awal_submisi: null,
        tanggal_akhir_submisi: null,
        tanggal_publikasi: null,
      },
      order: 'ASC',
      loadingFinalize: false,
    }
  },
  methods: {
    searchReportSetting() {
      this.fetchDataReportSchedule()
    },
    handleSelect(value) {
      if (this.search !== '') {
        this.fetchDataReportSchedule({
          level: this.selectedLevel,
          search: this.search,
        })
      } else {
        this.fetchDataReportSchedule({
          level: value,
        })
      }
      this.selectedLevel = value
    },
    fetchDataTahunAjaran() {
      this.$store.dispatch('report/FETCH_ALL_TAHUN_AJARAN').then((res) => {
        // console.log('fetchDataTahunAjaran =>', res)
        this.tahunAjarans = res
      })
    },
    async fetchDataReportSchedule() {
      this.loadingTableReportSubmission = true
      const payload = this.selectedLevel === 'all' ? { page: this.pagination.current } : { page: this.pagination.current, level: this.selectedLevel }
      const res = await this.$store.dispatch(
        'report/FETCH_ALL_REPORT_SUBMISSION', payload)
      // console.log('fetchDataReportSchedule =>', res)
      this.levelList = res.levelList
      const pagination = { ...this.pagination }
      pagination.total = res.total
      this.pagination = pagination
      this.loadingTableReportSubmission = false
      this.dataReportSubmission = res.reportSetting.map((row) => {
        return {
          key: row.id,
          idLevel: row.level.id,
          namaLevel: row.level.nama,
          tahunAjaran: `${
            row.tahun_ajaran.tahun
          } (${row.tahun_ajaran.semester.toUpperCase()})`,
          idTahunAjaran: row.tahun_ajaran.id,
          tipe: row.tipe.toUpperCase(),
          startAt: row.tanggal_awal_submisi
            ? moment(row.tanggal_awal_submisi).format('DD MMMM YYYY HH:mm')
            : null,
          endAt: row.tanggal_akhir_submisi
            ? moment(row.tanggal_akhir_submisi).format('DD MMMM YYYY HH:mm')
            : null,
          publication: row.tanggal_publikasi
            ? moment(row.tanggal_publikasi).format('DD MMMM YYYY HH:mm')
            : null,
        }
      })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.order = 'DESC'
      } else {
        this.order = 'ASC'
      }
      this.fetchDataReportSchedule()
    },
    handleChangeTahunAjaran(value) {
      this.newReportSubmission.id_tahun_ajaran = value
    },
    handleChangeType(e) {
      this.newReportSubmission.tipe = e.target.value
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    disabledStartDate(startValue) {
      const endValue = this.newReportSubmission.tanggal_akhir_submisi
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.newReportSubmission.tanggal_awal_submisi
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
    disabledBeforeNow(value) {
      return value.valueOf() < moment().add(-1, 'day').valueOf()
    },
    toggleModalAddReportSubmission(status, data) {
      this.modalReportSubmissionVisible = !this.modalReportSubmissionVisible
      if (!this.modalReportSubmissionVisible) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newReportSubmission = {
            id_tahun_ajaran: null,
            id_level: null,
            tipe: 'mid',
            tanggal_awal_submisi: null,
            tanggal_akhir_submisi: null,
            tanggal_publikasi: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }
      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          console.log(data)
          this.idEdit = data.key
          this.newReportSubmission = {
            id_tahun_ajaran: data.idTahunAjaran,
            id_level: data.idLevel,
            tipe: data.tipe.toLowerCase(),
            tanggal_awal_submisi: data.startAt
              ? moment(data.startAt, 'DD MMMM YYYY HH:mm')
              : null,
            tanggal_akhir_submisi: data.endAt
              ? moment(data.endAt, 'DD MMMM YYYY HH:mm')
              : null,
            tanggal_publikasi: data.publication
              ? moment(data.publication, 'DD MMMM YYYY HH:mm')
              : null,
          }
        }
      }
    },
    reportScheduleIsExist() {
      this.fetchDataReportSchedule()
      const isExist = Boolean(
        this.dataReportSubmission.find(
          (report) =>
            report.idTahunAjaran === this.newReportSubmission.id_tahun_ajaran &&
            report.tipe.toLowerCase() ===
              this.newReportSubmission.tipe.toLowerCase() &&
            report.idLevel === this.newReportSubmission.id_level,
        ),
      )
      return isExist
    },
    handleOkAddReportSubmission() {
      if (this.reportScheduleIsExist() && this.status.isCreate) {
        this.loadingAddReportSubmission = false
        this.toggleModalAddReportSubmission()
        return this.$notification.error({
          message: 'Error.',
          description:
            'Report Schedule you tried to create is already exist. Use edit to change it.',
        })
      } else {
        this.loadingAddReportSubmission = true
        const payload = this.status.isEdit
          ? { id: this.idEdit, data: this.newReportSubmission }
          : this.newReportSubmission
        this.$store
          .dispatch(
            `curriculum/${
              this.status.isEdit ? 'EDIT' : 'POST'
            }_REPORT_SUBMISSION`,
            payload,
          )
          .then((isSuccess) => {
            this.loadingAddReportSubmission = false
            this.toggleModalAddReportSubmission()
            if (isSuccess) {
              const description = this.status.isEdit
                ? 'Report Schedule has been edited'
                : 'New Report Schedule has been created'
              this.$notification.success({
                message: 'Success.',
                description,
              })
              this.fetchDataReportSchedule()
            } else {
              const description = this.status.isEdit
                ? 'Report Schedule has not been edited'
                : 'New Report Schedule has not been created'
              this.$notification.error({
                message: 'Error.',
                description,
              })
            }
          })
      }
    },
    deleteReportSchedule(data) {
      this.loadingDelete = true
      this.$confirm({
        title: 'Warning',
        content: (
          <div>
            Are you sure to delete report schedule for{' '}<b>{data.tipe.toLowerCase()}</b> report on <b>{data.tahunAjaran}</b>{' '}
            ? your data will be lost
          </div>
        ),
        onOk: () => {
          this.$store
            .dispatch('curriculum/DELETE_REPORT_SETTING', {
              idReportSetting: data.key,
            })
            .then((isDeleted) => {
              this.loadingDelete = false
              if (isDeleted) {
                this.$notification.success({
                  message: 'Success',
                  description: 'Report schedule has been removed',
                })
                this.fetchDataReportSchedule()
              } else {
                this.$notification.error({
                  message: 'Error.',
                  description: 'Report schedule cannot be deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDelete = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Remove',
      })
    },
    finalizeReport(record) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>
            Are you sure to finalize report on level {record.namaLevel} ? The report then will be fixed and <b>can't be changed anymore</b>.
          </div>
        ),
        onOk: () => {
          this.loadingFinalize = true
          this.loadingFinalize = false
        },
        onCancel: () => {
          this.loadingFinalize = false
        },
        centered: true,
        icon: 'warning',
        okType: 'primary',
        okText: 'Finalize',
      })
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
  mounted() {
    this.fetchDataTahunAjaran()
    this.fetchDataReportSchedule()
  },
}
</script>
